import React from 'react';
import styled from '@emotion/styled';
import {ReactComponent as Icon} from '../../images/logo.svg';
const config = require("/data/siteConfig");

const Wrapper = styled.div({
  display: 'flex',
  fontSize: 24
});

const StyledIcon = styled(Icon)({
  marginRight: '0.2857142857em'
});

export default function Logo() {
  return (
    <Wrapper>
      {/* having the height in `StyledApolloIcon` gets overridden by a 293px from emotion */}
      <StyledIcon style={{height: '2em'}} />
      {/*<p>{config.siteName}</p>*/}
    </Wrapper>
  );
}
