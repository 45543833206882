const config = require("/data/siteConfig");
// src/gatsby-theme-apollo-core/utils/color.js
const {colors} = require('gatsby-theme-apollo-core/src/utils/colors');
const {colors: spaceKitColors} = require('@apollo/space-kit/colors');

// noinspection JSValidateTypes
spaceKitColors.custom = {
  primary: config.primaryColor,
}

exports.colors = {
  ...colors,
  primary: spaceKitColors.custom.primary,
  primaryLight: spaceKitColors.custom.primary,
}
