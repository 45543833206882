const config = {
  siteName: "x200 Labs",
  siteTitle: "x200 Labs", // Site title.
  siteTitleShort: "x200", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "x200 Labs", // Alternative site title for SEO.
  siteLogo: "images/logo.png", // Logo used for SEO and manifest. Relative to /static
  siteUrl: "https://x200labs.com", // Domain of your website without pathPrefix.
  siteDescription: "x200 Labs", // Website description used for RSS feeds/meta description tag.
  siteAuthor: "x200 Labs", // Site author
  favIcon: "src/images/favicon.ico",
  baseUrl: "https://x200labs.com",
  pageTitle: 'Documentation',
  menuTitle: 'Menu Title',
  subtitle: 'x200 Labs',
  description: 'A guide to using x200 Labs products',
  primaryColor: "#3E78BC",
};

module.exports = config;
