import React from 'react';
import styled from '@emotion/styled';
import {IconProceed} from '@apollo/space-kit/icons/IconProceed';
import {breakpoints} from 'gatsby-theme-apollo-core';
import {size} from 'polished';

const config = require("/data/siteConfig");
const {colors: spaceKitColors} = require('@apollo/space-kit/colors');

// noinspection JSValidateTypes
spaceKitColors.custom = {
  primary: config.primaryColor,
}

const Container = styled.div({
  display: 'flex',
  flexShrink: 0,
  width: 240,
  [breakpoints.lg]: {
    width: 'auto',
    marginRight: 0
  },
  [breakpoints.md]: {
    display: 'none'
  }
});

const StyledLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  color: spaceKitColors.custom.primary,
  lineHeight: 2,
  textDecoration: 'none',
  ':hover': {
    color: spaceKitColors.custom.primary
  }
});

const StyledIcon = styled(IconProceed)(size('0.75em'), {
  marginLeft: '0.5em'
});

export default function HeaderButton() {
  return (
    <Container>
      <StyledLink
        href={config.siteUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {config.siteName}
        <StyledIcon weight="thin" />
      </StyledLink>
    </Container>
  );
}
